import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
// import styled, { ThemeProvider } from 'styled-components'
// import Carousel from '../Carousel'
// import Button from '../Button'
// import {light} from '../../styles/Themes';
import Loading from '../Loading';

const AboutVideo = lazy(() => import('../AboutVideo'));



// const Carousel = lazy(() => import("../Carousel"));

const Section = styled.section`
min-height: 80vh;
width: 100%;
background-color: ${props => props.theme.text};
display: flex;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;

`
const Container = styled.div`
width: 75%;
margin: 3em auto;
/* background-color: lightblue; */

display: flex;
justify-content: center;
align-items: center;
@media (max-width: 70em){
  width: 85%;
}

@media (max-width: 64em){
  width: 100%;
  flex-direction: column;

  &>*:last-child{
    width: 80%;
  }
}
@media (max-width: 40em){
  
  margin: 1.5em auto;

  &>*:last-child{
    width: 90%;
  }
}
`
const Box = styled.div`
width: 50%;
height: 100%;
min-height: 80vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 40em){
  min-height: 50vh;  
}
`
 
const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxl};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontxl};

}
`
const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight:400;
  
@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontlg};

}

`
const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight:400;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontmd};

}

`

const About = () => {
  return (
    <Section id="about">
      <Container>
      <Box>
        <Suspense fallback={<Loading />}>
          <AboutVideo /></Suspense>          
       </Box>
        <Box> 
        <Title>
        Welcome to <br />DAO Craft
        </Title>
        <SubText>
        We are fun-loving community builders that are cray about all things craft!
        </SubText>
        <SubTextLight>
        We collaborate with the craft community to produce craft-themed popups and exclusives in the physical world and in the metaverse. 
        Our popups are a series of intimate food, beverage, art & craft festivals. The diversity of the themes is designed to build community amongst lovers of fun, interesting products and experiences locally and overseas.
        </SubTextLight>
        <SubTextLight>
        We welcome all who want a life filled with the wonders of craft to join us on our trails to explore the craft of beers, wines, spirits, and other collectibles at friendly budgets. 
        
        </SubTextLight>
         </Box>
      </Container>
    </Section>
  )
}

export default About

/*

        We launched our first popup on 20.02.2022, themed "The Dao of Craft Beer". More than 200 craft beer lovers attended the event! All the 5 craft beer workshops were sold out!
        And all our craft partners from 1925 Brewing Co to iBrew, Oasis, Orh Gao, TSA and Wet Goods were all happy with the turn out. 


The DAO of craft - the way of the craft - inspires us and fills our life with meaning as we partake in the creation, appreciation and promotion of craft in our lifetime. 

We believe DAOs, decentralised autonomous organisations, are paths to the future. A future where like-minded people work together without a central authority towards a common goal. We aim to setup the world’s first DAO to support and champion craftsmanship with techies in our community. 

The first Genesis Collection comprises just 999 non-fungible tokens. Each unique,  randomly generated token is a Jack, a playing card which features a craft. To play and enjoy all the benefits of the DAO CRAFT ecosystem, players must have at least one jack. To open a wager, a pair is required. More on the gameplay later.

This first Genesis Collection will ever only mint 999 Jack of Crafts (a.k.a. non-fungible tokens) on the Polymatic blockchain, making them extremely rare. Each Jack is programmatically generated with randomised traits, some more rare than others. These collectibles grant the holder benefits throughout the DAO CRAFT community. 
*/